import { useContentfulImages } from '@snapchat/mw-global-components';
import type { CarouselV3AspectRatios } from '@snapchat/snap-design-system-marketing';
import { CarouselCardItem as CarouselCardItemSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import type React from 'react';
import { useContext, useState } from 'react';

import { isStringLocalUrl } from '../../helpers/getLocalPath';
import { logger } from '../../helpers/logging/loggingInstance';
import { UserAction } from '../../types/events';
import { getFileInfo } from '../../utils/getFileInfo';
import { parseMedia } from '../../utils/media';
import { ConsumerContext } from '../ConsumerContextProvider';
import type { VideoDataProps } from '../Video/types';
import { getVideoWatchLogger } from '../Video/utils';
import type { CarouselV3CardItemProps } from './types';

export const CarouselV3VideoItem: FC<{
  cardItem: CarouselV3CardItemProps;
  mediaAspectRatio: CarouselV3AspectRatios;
  enableVideoControls: boolean;
  isVisible?: boolean;
  shouldLoad?: boolean;
  className?: string;
}> = ({
  cardItem,
  mediaAspectRatio,
  enableVideoControls,
  isVisible,
  shouldLoad,
  ...otherProps
}) => {
  const [videoPlayed, setVideoPlayed] = useState(false);
  let prevWatchEventTime = -1;
  const consumerContext = useContext(ConsumerContext);
  const { logEvent } = consumerContext;

  const videoMedia = cardItem.media as VideoDataProps;

  const { getBestBgImgSrc } = useContentfulImages();
  const posterSource = getBestBgImgSrc(videoMedia.thumbnailMedia?.url, {
    // Double max width for the image.
    width: 728,
  });

  const { videoSource } = parseMedia(cardItem.media.media);
  const { videoSource: mobileVideoSource } = parseMedia(cardItem.media.mobileMedia);

  // The CarouselV3VideoItem mimics the content metrics from the Video component
  const onPlay: React.ReactEventHandler<HTMLVideoElement> = _e => {
    if (logEvent && videoSource && !videoPlayed) {
      if (videoMedia.autoPlay) return; // Skip for auto play videos
      const { fileName } = getFileInfo(videoSource);
      logEvent({ component: 'Video', type: UserAction.Play, label: fileName });
      setVideoPlayed(true);
    }
  };

  const onTimeUpdate = getVideoWatchLogger({
    autoPlay: videoMedia.autoPlay,
    eventLabel: videoMedia.externalId ?? cardItem.sys.id,
    getPreviousWatchTime: () => {
      return prevWatchEventTime;
    },
    setPreviousWatchTime: value => {
      prevWatchEventTime = value;
    },
  });

  const slug = cardItem.slugReference?.slug;
  const url = slug ?? cardItem.url;

  const onClick = (): void => {
    if (!url) {
      return;
    }

    logEvent?.({
      type: UserAction.Click,
      component: 'CarouselV3',
      label: `CarouselV3.VideoItem - ${url}`,
      url,
    });

    if (!isStringLocalUrl(url)) {
      void logger.flush();
    }
  };

  return (
    <CarouselCardItemSDS
      aspectRatio={mediaAspectRatio}
      body={cardItem.body}
      showVideoControls={enableVideoControls}
      key={cardItem.sys.id}
      subtitle={cardItem.subtitle}
      title={cardItem.title}
      url={url}
      videoSource={videoSource}
      mobileVideoSource={mobileVideoSource}
      onPlay={onPlay}
      onTimeUpdate={onTimeUpdate}
      isVisible={isVisible}
      shouldLoad={shouldLoad}
      onClick={onClick}
      posterSource={posterSource}
      {...otherProps}
    />
  );
};

CarouselV3VideoItem.displayName = 'CarouselV3VideoItem';
